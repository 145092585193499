import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/exo-face2.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'





const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 16px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 100%;
  }
  @media ${device.md} {
    max-width: 100%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardContPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="#ffffff">
      <Container>

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">Impact on Hair Restoration and Face Rejuvenation?</Itext>
</Iwrap>
      <Secondtext>
      Benefits In Choosing Exosome Therapy for Hair and Face?

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0 order-lg-2">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="prp beard restoration" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9" className="0rder-lg-1">
          
          
             

         
              
          <ul>
  <li><b>Natural Regeneration:</b> Exosome therapy harnesses the body's natural regenerative capabilities by utilizing exosomes derived from salmon. This approach promotes cellular repair and tissue regeneration without the need for synthetic substances.</li> <br />
  <li><b>Hair Growth Promotion:</b> Exosomes contain growth factors and signaling molecules that stimulate hair follicles, leading to improved hair growth and density.</li> <br />
  <li><b>Facial Skin Rejuvenation:</b> The application of exosomes to facial skin stimulates collagen production, which can help reduce wrinkles, improve skin texture, and enhance overall skin health.</li> <br />
  <li><b>Non-invasive Procedure:</b> Unlike surgical options, exosome therapy is a non-invasive procedure that typically involves topical application to the scalp or skin, making it a safer and more comfortable option for many individuals.</li> <br />
  <li><b>Minimal Downtime:</b> Exosome therapy usually requires minimal downtime, allowing individuals to resume their daily activities shortly after treatment.</li> <br />
  <li><b>Long-lasting Results:</b> With consistent treatment, exosome therapy can yield long-lasting results, providing ongoing improvements in hair growth and skin rejuvenation over time.</li>
</ul>







            
            
            
             <br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
           
         
          </Col>
        </Row>




        
    
              
            
           
           
      </Container>
    </Section>
  </>
);

export default PRPhairBeardContPage;
